import {
  OverlayArrow,
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
  composeRenderProps,
  PopoverContext,
  useSlottedContext,
} from "react-aria-components";
import React from "react";
import { tv } from "tailwind-variants";

export interface PopoverProps extends Omit<AriaPopoverProps, "children"> {
  showArrow?: boolean;
  children: React.ReactNode;
  variant?: "primary" | "information";
  id?: string;
}

const styles = tv({
  base: "bg-white forced-colors:bg-[Canvas] shadow-md rounded bg-clip-padding border",
  variants: {
    variant: {
      primary: "border-secondary-main-10 text-secondary-main-100 py-2",
      information: "border-information-main-10 text-information-main-1",
    },
    isEntering: {
      true: "animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 ease-out duration-200",
    },
    isExiting: {
      true: "animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 ease-in duration-150",
    },
  },
  defaultVariants: { variant: "primary" },
});

/**
 * React-aria-components Popover implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/popover--docs)
 * @param props containing
 * children: JSX element to display in the popover
 * showArrow: boolean indicating if the arrow should be displayed or not
 * className: string to style the component
 * variant: tailwind variant to style the component (possible values: "primary" | "information")
 */
export function Popover({
  children,
  showArrow,
  className,
  variant,
  ...props
}: PopoverProps) {
  let popoverContext = useSlottedContext(PopoverContext)!;
  let isSubmenu = popoverContext?.trigger === "SubmenuTrigger";
  let offset = showArrow ? 12 : 8;
  offset = isSubmenu ? offset - 6 : offset;
  return (
    <AriaPopover
      offset={offset}
      {...props}
      className={composeRenderProps(className, (className, renderProps) =>
        styles({ ...renderProps, variant, className })
      )}
    >
      {showArrow && (
        <OverlayArrow className="group">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="block fill-white forced-colors:fill-[Canvas] stroke-1 stroke-secondary-main-20 forced-colors:stroke-[ButtonBorder] group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </AriaPopover>
  );
}

// Due to a bug in react-aria-components (https://github.com/adobe/react-spectrum/issues/6804)
// We create our own menu-like component
export const menuItemStyles = tv({
  base: "group flex items-center text-secondary-main-100 gap-2 cursor-pointer select-none py-2 px-4 text-sm hover:bg-primary-main-50",
  variants: {
    isDisabled: {
      true: "text-sidebar-gray-hover forced-colors:text-[GrayText]",
    },
  },
});
